import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import("@/views/IndexView"),
    meta: {
      layout: "Main",
    },
  },
  {
    path: "/expert-list/",
    name: "ExpertsList",
    component: () => import("@/views/ExpertsList"),
    meta: {
      layout: "Main",
    },
  },
  {
    path: "/expert/:pk",
    name: "Expert",
    component: () => import("@/views/ExpertProfile"),
    props: true,
    meta: {
      layout: "Main",
    },
  },
  {
    path: "/expert/calendar/date/:expert_skill_id",
    name: "ExpertCalendar",
    component: () => import("@/views/CalendarDateView"),
    props: true,
    meta: {
      layout: "Main",
    },
  },
  {
    path: "/expert/calendar/time/:expert_skill_id/:date",
    name: "ExpertCalendarTime",
    component: () => import("@/views/CalendarTimeView"),
    props: true,
    meta: {
      layout: "Main",
    },
  },
  {
    path: "/request-login",
    name: "requestLogin",
    component: () => import("@/views/RequestLoginView"),
    props: true,
    meta: {
      layout: "Auth",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/LoginView"),
    props: true,
    meta: {
      layout: "Auth",
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/RegisterMentee"),
    props: true,
    meta: {
      layout: "Auth",
    },
  },
  {
    path: "/payment/:gateway/callback",
    name: "PaymentCallback",
    component: () => import("@/views/PaymentCallback"),
    props: true,
    meta: {
      layout: "Main",
    },
  },
  {
    path: "/become-mentor",
    name: "BecomeMentor",
    component: () => import("@/views/BecomeMentor"),
    props: true,
    meta: {
      layout: "Main",
    },
  },
  {
    path: "/event/:pk",
    name: "Event",
    component: () => import("@/views/EventPage"),
    props: true,
    meta: {
      layout: "Main",
    },
  },
  {
    path: "/success-enroll/",
    name: "SuccessEnroll",
    component: () => import("@/views/CourseEnroll"),
    meta: {
      layout: "Main",
    },
  },
  {
    path: "/fc/:expert_skill_id",
    name: "FC",
    component: () => import("@/views/CalendarView"),
    props: true,
    meta: {
      layout: "Main",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("@/views/NotFound"),
    meta: {
      layout: "Main",
    },
  },
  {
    path: "/:pathMatch(.*)",
    name: "bad-not-found",
    component: () => import("@/views/NotFound"),
    meta: {
      layout: "Main",
    },
  },
];

const router = createRouter({
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
  history: createWebHistory(),
  routes,
});

export default router;
