<template>
  <notifications position="top left" />
  <component :is="layout">
    <router-view />
  </component>
</template>

<script setup>
// TODO: redirect to previous page after user logged in
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/user";
import http from "@/api/api.service";
import { useRoute } from "vue-router";
import { computed, onBeforeMount, onMounted, watch } from "vue";

const route = useRoute();
/**
 *  This is a computed property that will return the name
 *  of the current route
 */
const layout = computed(() => {
  const layout = route?.meta?.layout;

  if (layout) {
    return `The${layout}Layout`;
  }
  return "div";
});

const { fetchBasicProfile } = useAuthStore();
const { loggedIn, token } = storeToRefs(useAuthStore());

if (token.value !== null) {
  http.defaults.headers.common["Authorization"] = token.value;
}

if (loggedIn.value) {
  fetchBasicProfile()
    .then(() => {})
    .catch(() => {
      loggedIn.value = false;
      delete http.defaults.headers.common["Authorization"];
      useAuthStore().$reset();
    });
}

const closeMenuHandler = () => {
  let collapsibleNavbar = document.querySelector("#collapsibleNavbar");
  let toggleButton = document.querySelector(".navbar-toggler");
  if (toggleButton && collapsibleNavbar) {
    if (collapsibleNavbar.classList.contains("show")) {
      toggleButton.click();
    }
  }
};

watch(route, () => {
  closeMenuHandler();
});

onMounted(() => {
  window.addEventListener("scrollend", closeMenuHandler);
});

onBeforeMount(() => {
  window.removeEventListener("scrollend", closeMenuHandler);
});
</script>

<style>
/* @font-face {
  font-family: "Estedad";
  src: url("@/assets/font/Estedad-FD[KSHD,wght].woff2") format("woff2")
} */

@import url("@/assets/font/Pelak/stylesheet.css");

body {
  font-family: Pelak !important;
  color: rgb(51, 52, 86);
}

.bg-blue {
  background-color: #01a2f5;
}

.vue-notification {
  margin: 60px 0 !important;
  font-size: larger !important;
  text-align: start !important;
}
</style>
