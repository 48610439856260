import {API_URL} from "../../public/config";
import axios from "axios";

let headers = {
  "Content-type": "application/json",
};


export default axios.create({
  baseURL: API_URL,
  headers: headers
})
