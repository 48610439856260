import {createApp} from 'vue'
import App from './App.vue'
import router from "./router";
import axios from "axios";
import {createPinia} from "pinia";
import VueAxios from "vue-axios";
import "bootstrap/dist/css/bootstrap.rtl.min.css"
import "bootstrap"
import "bootstrap-icons/font/bootstrap-icons.css"
import Notifications from '@kyvg/vue3-notification'
import MainLayout from './layouts/MainLayout.vue';
import AuthLayout from './layouts/AuthLayout.vue';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import { createHead } from '@unhead/vue'

const app = createApp(App);
app.use(VueTelInput);

app.component('TheMainLayout', MainLayout);
app.component('TheAuthLayout', AuthLayout);

const head = createHead()
app.use(head);


app.use(router)
    .use(createPinia())
    .use(VueAxios, axios)
    .use(Notifications)
    .mount('#app');


// createApp(App).use(router)
// .use(createPinia())
// .use(VueAxios, axios)
// .use(Notifications)
// .mount('#app');